<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('moveLogFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="dark" square size="sm"
                @click="openModalEvent('moveLog', 'moveLogForm', null, 'Stok Kaydırma Ekranı', null)"
              >
                Stok Hareketi
              </CButton>
              &nbsp
              <CButton color="warning" square size="sm"
                @click="openModalEvent('moveLogSpecial', 'moveLogSpecialForm', null, 'Stok Kaydırma Ekranı Özel', null)"
              >
                Stok Hareketi Özel
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="moveLogs"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="getMoveLog(lastItem.id)"
            v-on:refresh="filterMoveLog"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                >
                  <CDropdownItem @click="getMoveLog(item.id)">Hareket Detayı</CDropdownItem>
                  <CDropdownItem @click="getExcel(item.id)">Excel</CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #posFrom="{item}">
              <td> {{ item.posFrom !== '' ? item.posFrom.name : '' }}</td>
            </template>
            <template #posTo="{item}">
              <td> {{ item.posTo !== '' ? item.posTo.name : '' }} </td>
            </template>
            <template #inventoryMoveName="{item}">
              <td> {{ item.inventoryMove !== '' ? item.inventoryMove.name : '' }} </td>
            </template>
            <template #createTime="{item}">
              <td> {{ item.createTime ? dateFormat(item.createTime) : '' }} </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  
    <FormModal 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="moveLogCrud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :additionalButtons = "additionalButtons"
      :additionalEvent = "additionalEvent"
    />
    <ConfirmModal
      :show="openModalConfirm" 
      :title="modalTitle" 
      :openModalEvent="openModalConfirmEvent"
      :confirm="moveLogCrud"
      :desc="modalDesc"
      :actionType="actionType"
      :loading="loading"
      :noFooter="true"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterMoveLog"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
    
  </CRow>
</template>
<script>
import FilterSidebar from '../components/filterSidebar'
import ConfirmModal from '../components/confirmModal'
import FormModal from '../components/formModal'
import moment from "moment";
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'InventoryMoveLog',
  components: {FilterSidebar, FormModal, ConfirmModal},
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'inventory',
      form: 'moveLogForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      lastItem: {},
      openSidebar: false,
      filterForm: 'moveLogFilter',
      filterParams: {},
      additionalButtons: [],
      additional: false,
      activePage: 1,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: "Hareket Id", _style: 'font-size:12px'},
        {key: 'posFrom', label: 'Alınan_Stok_Kanalı', _style: 'font-size:12px'},
        {key: 'createTime', label: 'Hareket Zamanı', _style: 'font-size:12px'},
        {key: 'inventoryMoveName', label: 'Hareket Adı', _style: 'font-size:12px'},
        {key: 'posTo', label: 'Verilen_Stok_Kanalı', _style: 'font-size:12px'},
        {key: 'numFound', label: 'Bulunan', _style: 'font-size:12px'},
        {key: 'numMoved', label: 'Taşınan', _style: 'font-size:12px'},
        {key: 'totalAmountFound', label: 'Bulunan Toplam', _style: 'font-size:12px'},
        {key: 'totalAmountMoved', label: 'Taşınan Toplam', _style: 'font-size:12px'}
      ],
      filters: [{
        type: 'numeric',
        dataIndex: 'id',
        field: 'id',
        comparison: 'eq'
      }, {
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt'
      }, {
        type: 'date',
        dataIndex: 'endTime',
        field: 'createTime',
        comparison: 'lt'
      },{
        type: 'list',
        dataIndex: 'posFrom_id',
        field: 'posFrom.id',
      },{
        type: 'list',
        dataIndex: 'posTo_id',
        field: 'posTo.id',
      }, {
        type: 'integer',
        dataIndex: 'numFound',
        field: 'numFound',
        comparison: 'eq'
      }, {
        type: 'integer',
        dataIndex: 'numMoved',
        field: 'numMoved',
        comparison: 'eq'
      }, {
        type: 'integer',
        dataIndex: 'totalAmountFound',
        field: 'totalAmountFound',
        comparison: 'eq'
      }, {
        type: 'integer',
        dataIndex: 'totalAmountMoved',
        field: 'totalAmountMoved',
        comparison: 'eq'
      },
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },

  },
  computed: {
    moveLogs: function(){
      return this.$store.getters.moveLog;
    },
    loading: function(){
      return this.$store.getters.inventoryLoading;
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.additionalButtons = [{name: 'Hareketi Simüle Et', color: 'warning', action: 'simulate'}]
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openModalConfirmEvent(actionType, desc){
      this.openModalConfirm = !this.openModalConfirm
      this.actionType = actionType ? actionType : this.actionType
      this.modalDesc = desc
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    rowClicked(item, index) {
      this.lastItem = item;
    },

    async additionalEvent(data, action){
      let params = {...data}
      if(action == 'simulate'){
        params.commit = false
        this.moveLogCrud(params, this.actionType)
      }
    },

    async moveLogCrud(item, action){
        let formData = new FormData()
        
        formData.append('commitMove', item.commit !== false ? true : false)
        item.fromPosId && formData.append('fromPosId', item.fromPosId)
        item.toPosId && formData.append('toPosId', item.toPosId)

        if(action == 'moveLogSpecial'){
          item.description && formData.append('description', item.description)
          formData.append('allOrNone', item.allOrNone ? item.allOrNone : false)
          item.skuCodeAmounts && formData.append('skuCodeAmounts', item.skuCodeAmounts)
        }else if(action == 'moveLog'){
          item.moveId && formData.append('moveId', item.moveId)
          item.productId && formData.append('productId', item.productId)
        }

      await this.$store.dispatch('moveLog_'+this.actionType, formData)
      if(this.$store.getters.inventoryStatus.success) {
        if(item.commit == false){
         this.openModalConfirmEvent(this.actionType,  this.$store.getters.inventoryStatus.message)
        }else{
          this.filterMoveLog(this.filterParams);
          this.openModalEvent() 
        }
      }
    },

    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },

    async getMoveLog(id) {
      await this.$store.dispatch('moveLog_get', {id: id})
      if(this.$store.getters.inventoryStatus.success) {
        this.openModalConfirmEvent(this.actionType,  this.$store.getters.inventoryStatus.message)
      }
    },

    async getExcel(id) {
      await this.$store.dispatch('moveLog_excel', {inventoryMoveLogId: id, responseType: 'blob'})
      if(this.$store.getters.inventoryStatus) {
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.inventoryStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'StokTasimaOzel_' + moment(new Date()).format('DD/MM/YYYY') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },
   
    async filterMoveLog(params){
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('moveLog_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.filterMoveLog(this.filterParams);
    this.$store.dispatch('activePos_list')
    this.$store.dispatch('activeProduct_list')
    this.$store.dispatch('activeInventoryMove_list')
  }
}
</script>
